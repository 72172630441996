// LoginPage.vue
<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm5>
                <v-card class="elevation-12">
                    <v-toolbar color="yellow">
                        <v-toolbar-title>Formulario de ingreso</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container grid-list-md>
                                <v-row>
                                    <v-layout wrap>
                                        <v-flex xs12>
                                            <v-text-field
                                                prepend-icon="mdi-account"
                                                name="username"
                                                label="Nombre de usuario"
                                                type="text"
                                                v-model="username"
                                                required
                                            ></v-text-field>

                                            <v-text-field
                                                prepend-icon="mdi-lock"
                                                label="Confirma Password"
                                                :append-icon="
                                                    show
                                                        ? 'mdi-eye'
                                                        : 'mdi-eye-off'
                                                "
                                                :type="
                                                    show ? 'text' : 'password'
                                                "
                                                @click:append="show = !show"
                                                required
                                                v-model="password"
                                                :rules="passwordRules"
                                                data-cy="joinPasswordField"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-tooltip top v-if="!confirmado">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="yellow"
                                    v-on="on"
                                    :disabled="!valid"
                                    to="/Identity/ResendConfirmationEmail"
                                    data-cy="joinSubmitBtn"
                                    class="text-none"
                                >
                                    <v-icon>mdi-email-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Reenvía EMail de confirmación </span>
                        </v-tooltip>

                        <v-spacer></v-spacer>
                        <v-btn
                            color="yellow"
                            :disabled="!valid"
                            to="/Identity/Forgot"
                            data-cy="joinSubmitBtn"
                            class="text-none"
                            >Olvido su password?</v-btn
                        >

                        <v-btn
                            color="yellow"
                            :disabled="!valid"
                            @click="submit"
                            data-cy="joinSubmitBtn"
                            class="text-none"
                        >
                            Confirma
                            <span>
                                <v-progress-circular
                                    indeterminate
                                    v-if="progress"
                                >
                                </v-progress-circular>
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            username: '',
            password: '',
            show: false,
            passwordRules: [
                v => !!v || 'Se requiere password',
                v => v.length >= 6 || 'Password debe tener más de 6 caracteres'
            ],
            loading: false,
            valid: false
        };
    },
    computed: {
        ...mapState('account', ['confirmado', 'progress'])
    },
 
    methods: {
        ...mapActions('account', ['login']),
        ...mapActions('alert', ['clear']),
        submit() {
            this.loading = true;
            if (this.$refs.form.validate()) {
                const { username, password } = this;
                if (username && password) {
                    this.login({ username, password });
                }
                this.loading = false;
            }
        }
    }
};
</script>
